import * as React from "react"
import Button from "../components/elements/Button"
import Layout from "../components/layout"

// styles
const pageStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const NotFoundPage = () => {
    return (
        <>

            <Layout>
                <div style={{ paddingTop: 200, paddingBottom: 100 }}>
                    <h1>404<br />Seite nicht gefunden</h1>

                    <p>Wir konnten die Seite, die Sie suchen leider nicht finden.</p>

                    <Button type="line" title="zur Startseite" destination="/" />
                </div>
            </Layout>
        </>
    )
}

export default NotFoundPage